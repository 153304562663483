import { createContext, useContext } from "react";

export { darkTheme } from "./dark";
export { lightTheme } from "./light";

export const ThemeContext = createContext({
  dark: false,
  changeTheme: (dark) => {},
});

export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  return context;
};
