export const ru = {
  header: {
    navbar: {
      blog: "Блог",
      community: "Сообщество",
      support: "Поддержать",
    },
    account: "Аккаунт",
  },
  footer: {
    terms: "Условия использования",
    privacy: "Политика конфиденциальности",
    contact: "Контакт",
    faq: "FAQ",
  },
};
