import "./core/locales";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import { HomePage, TermsPage, PrivacyPage } from "./app/pages";
import Header from "./app/components/Header";
import Footer from "./app/components/Footer";

const Root = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  height: 100%;
  min-height: 100vh;
  width: 100%;
  min-width: 100vw;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - 60px);
`;

function Application() {
  return (
    <Root>
      <Header />
      <Content>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
        </Routes>
      </Content>
      <Footer />
    </Root>
  );
}

export default Application;
