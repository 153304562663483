export const uk = {
  header: {
    navbar: {
      blog: "Блог",
      community: "Спільнота",
      support: "Підтримати",
    },
    account: "Рахунок",
  },
  footer: {
    terms: "Умови використання",
    privacy: "Політика конфіденційності",
    contact: "Контакти",
    faq: "FAQ",
  },
};
