import React, { useCallback, useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import {
  MdArrowBack,
  MdDeveloperBoard,
  MdOutlineCoffee,
  MdOutlineDarkMode,
  MdOutlineGroups,
  MdOutlineLanguage,
  MdOutlineLightMode,
  MdOutlineMenu,
  MdPersonOutline,
} from "react-icons/md";
import Drawer from "react-modern-drawer";
import { useState } from "react";
import { useThemeContext } from "../../core/theme";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import { useTranslation } from "react-i18next";
import { Link as LinkRouter } from "react-router-dom";

const Container = styled.div`
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeaderContainer = styled(Container)`
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 16px;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  height: 60px;
  margin-bottom: 40px;
  box-shadow: 0px 11px 36px 7px rgba(0, 0, 0, 0.02);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const HeaderTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 12px;
  position: relative;
  color: ${(props) => props.theme.colors.text};
  > span {
    font-weight: 400;
    font-size: 0.8rem;
    position: absolute;
    right: -10px;
    top: -5px;
    color: #ff4a4a;
  }
`;

const Logo = styled.img`
  width: 38px;
  height: 38px;
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const Navigation = styled(Box)`
  justify-content: flex-start;
  width: 100%;
  margin-left: -180px;
`;

const MobileNavigation = styled(Navigation)`
  flex-direction: column;
  margin-left: 0px;
  height: 100%;
`;

const selectedNavLink = css`
  background-color: ${(props) => props.theme.colors.primary_light};

  > svg {
    color: #21cbf3;
  }
`;

const NavLink = styled.a`
  margin: 0px 8px;
  color: ${(props) => props.theme.colors.text};
  text-decoration: none;
  border-radius: 50px;
  height: 40px;
  padding: 2px 20px;
  display: flex;
  align-items: center;
  font-weight: 500;

  background-color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary_light};
    > svg {
      color: #2196f3;
    }
  }

  ${({ selected }) => selected && selectedNavLink}
`;

const MobileNavLink = styled(NavLink)`
  width: 100%;
  height: 50px;
  margin: 8px 0px;
`;

const Link = styled(LinkRouter)`
  color: inherit;
  text-decoration: none;
`;

const AccountButton = styled.a`
  color: inherit;
  text-decoration: none;
  min-width: 120px;
  border-radius: 50px;
  background: rgb(33, 150, 243);
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 0%,
    rgba(33, 203, 243, 1) 70%,
    rgba(0, 212, 255, 1) 100%
  );
  transition: all 0.3s 0s ease;
  color: white;
  padding: 10px 16px;
  text-align: center;

  display: flex;
  align-items: center;
  &:hover {
    transform: scale(1.05);
  }
`;

const LanguageButton = styled.button`
  border: none;
  cursor: pointer;

  color: ${(props) => props.theme.colors.text};
  text-decoration: none;
  border-radius: 50px;
  height: 40px;
  padding: 2px 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: inherit;

  background-color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary_light};
    > svg {
      color: #2196f3;
    }
  }

  ${({ selected }) => selected && selectedNavLink}
`;

const ThemeButton = styled(LanguageButton)`
  margin: 0px;
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 16px;
`;

const MobileUserButton = styled(ThemeButton)`
  background-color: ${(props) => props.theme.colors.primary_light};
  > svg {
    color: #2196f3;
  }
`;

const DesktopBox = styled.div`
  display: none;
  @media screen and (min-width: 1080px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const MobileBox = styled.div`
  display: none;
  @media screen and (max-width: 1080px) {
    display: flex;
  }
`;

const Burger = styled(ThemeButton)`
  margin-right: 0px;
`;

const DrawerBox = styled.div`
  padding: 12px;
  height: 100%;
  padding-bottom: calc(18px + env(safe-area-inset-bottom));
  background-color: ${(props) => props.theme.colors.white};
`;

const MenuBox = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 16px 0px;
  border-radius: 12px;
  color: ${(props) => props.theme.colors.text};
  min-width: 120px;
  margin-top: 20px;
  margin-left: -50px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

const selectedStyledMenuItem = css`
  background-color: ${(props) => props.theme.colors.primary_light};
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ selected }) => selected && selectedStyledMenuItem}

  &:hover {
    background-color: ${(props) => props.theme.colors.primary_light};
  }
`;

const ShortBox = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 6px;
  color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  margin-right: 8px;
  font-weight: bold;
  font-size: 0.9rem;
`;

const languages = [
  { title: "English", short: "EN" },
  { title: "Беларуская", short: "BE" },
  { title: "Русский", short: "RU" },
  { title: "Українська", short: "UK" },
];

const Header = () => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const ref = useRef(null);
  const [isOpenLanguage, setIsOpenLanguage] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const theme = useThemeContext();

  const toggleTheme = useCallback(() => {
    theme.changeTheme(!theme.dark);
  }, [theme]);

  const ThemeIcon = useMemo(() => {
    if (theme.dark) {
      return MdOutlineLightMode;
    }

    return MdOutlineDarkMode;
  }, [theme.dark]);

  return (
    <Wrapper>
      <HeaderContainer>
        <Link to="/">
          <Box>
            <Logo src={"./assets/images/logo.png"} />
            <HeaderTitle>
              Community<span>β</span>
            </HeaderTitle>
          </Box>
        </Link>
        <DesktopBox>
          <Navigation>
            <NavLink href="https://t.me/s/vivysub" target="_blank">
              <MdDeveloperBoard size={24} style={{ marginRight: 8 }} />
              {t("header:navbar.blog")}
            </NavLink>
            <NavLink href="https://community.vivysub.app">
              <MdOutlineGroups size={24} style={{ marginRight: 8 }} />
              {t("header:navbar.community")}
            </NavLink>
            <NavLink href="https://patreon.com/vivysub" target="_blank">
              <MdOutlineCoffee size={24} style={{ marginRight: 8 }} />
              {t("header:navbar.support")}
            </NavLink>
          </Navigation>
        </DesktopBox>
        <Box>
          <LanguageButton
            ref={ref}
            onClick={() => setIsOpenLanguage(!isOpenLanguage)}
            selected={isOpenLanguage}
          >
            {language.toLocaleUpperCase()}
            <MdOutlineLanguage size={24} style={{ marginLeft: 8 }} />
          </LanguageButton>
          <ControlledMenu
            state={isOpenLanguage ? "open" : "closed"}
            anchorRef={ref}
            onMouseLeave={() => setIsOpenLanguage(false)}
            onClose={() => setIsOpenLanguage(false)}
          >
            <MenuBox>
              {languages.map(({ title, short }) => {
                return (
                  <StyledMenuItem
                    key={short}
                    selected={language.toLocaleUpperCase() === short}
                    onClick={() => {
                      changeLanguage(short.toLocaleLowerCase());
                    }}
                  >
                    <ShortBox>{short}</ShortBox>
                    {title}
                  </StyledMenuItem>
                );
              })}
            </MenuBox>
          </ControlledMenu>
          <MobileBox>
            <Burger onClick={toggleDrawer}>
              <MdOutlineMenu size={28} />
            </Burger>
            <Drawer open={isOpen} onClose={toggleDrawer} direction="right">
              <DrawerBox>
                <MobileNavigation>
                  <Box
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      padding: "2px 10px",
                    }}
                  >
                    <Box>
                      <ThemeButton onClick={toggleDrawer}>
                        <MdArrowBack size={24} />
                      </ThemeButton>
                      <ThemeButton
                        style={{ marginRight: -6 }}
                        onClick={toggleTheme}
                      >
                        <ThemeIcon size={24} />
                      </ThemeButton>
                    </Box>
                    <MobileUserButton style={{ marginRight: -4 }} hovered>
                      <MdPersonOutline size={24} />
                    </MobileUserButton>
                  </Box>
                  <MobileNavLink href="https://t.me/s/vivysub" target="_blank">
                    <MdDeveloperBoard size={24} style={{ marginRight: 8 }} />
                    {t("header:navbar.blog")}
                  </MobileNavLink>
                  <MobileNavLink href="https://community.vivysub.app">
                    <MdOutlineGroups size={24} style={{ marginRight: 8 }} />
                    {t("header:navbar.community")}
                  </MobileNavLink>
                  <MobileNavLink
                    href="https://patreon.com/vivysub"
                    target="_blank"
                  >
                    <MdOutlineCoffee size={24} style={{ marginRight: 8 }} />
                    {t("header:navbar.support")}
                  </MobileNavLink>
                </MobileNavigation>
              </DrawerBox>
            </Drawer>
          </MobileBox>
          <DesktopBox>
            <ThemeButton onClick={toggleTheme}>
              <ThemeIcon size={24} />
            </ThemeButton>
            <AccountButton href="https://my.vivysub.app">
              <MdPersonOutline size={24} style={{ marginRight: 8 }} />
              {t("header:account")}
            </AccountButton>
          </DesktopBox>
        </Box>
      </HeaderContainer>
    </Wrapper>
  );
};

export default Header;
