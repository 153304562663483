export const be = {
  header: {
    navbar: {
      blog: "Блог",
      community: "Супольнасць",
      support: "Падтрымаць",
    },
    account: "Улік. запіс",
  },
  footer: {
    terms: "Умовы выкарыстання",
    privacy: "Палітыка прыватнасці",
    contact: "Кантакты",
    faq: "FAQ",
  },
};
