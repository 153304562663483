export const en = {
  header: {
    navbar: {
      blog: "Blog",
      community: "Community",
      support: "Support",
    },
    account: "Account",
  },
  footer: {
    terms: "Terms of Use",
    privacy: "Privacy Policy",
    contact: "Contact",
    faq: "FAQ",
  },
};
