import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container } from "./Container";

const FooterLink = styled(Link)`
  margin: 8px 16px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  padding: 6px 12px;
  border-radius: 12px;

  @media screen and (max-width: 680px) {
    padding: 16px 18px;
    margin: 4px 0px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary_light};
  }
`;

const FooterLinks = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Copyright = styled.p`
  color: ${(props) => props.theme.colors.text};
  text-align: center;
  line-height: 1.6;
`;

const Wrapper = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.white};
`;

const SimpleLink = styled.a`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container>
        <FooterLinks>
          <FooterLink to="/terms">{t("footer:terms")}</FooterLink>
          <FooterLink to="/privacy">{t("footer:privacy")}</FooterLink>
          <FooterLink to="/">{t("footer:contact")}</FooterLink>
          <FooterLink to="/">{t("footer:faq")}</FooterLink>
        </FooterLinks>
        <Copyright>
          © {new Date().getFullYear()} VivySub.Community
          <br /> Created by{" "}
          <SimpleLink href="https://vivy.company">Vivy.Company</SimpleLink> with
          ❤️
        </Copyright>
      </Container>
    </Wrapper>
  );
};

export default Footer;
